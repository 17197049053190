<template>
  <div>
    <iframe :src="srcDocument" frameborder="0" style="overflow:hidden;height:88vh;width:100%" width="100%"></iframe>
  </div>
</template>
<script>
export default{
  data() {
    return {
      srcDocument: '',
    }
  },
  computed: {
    partnerid() {
      return this.$store.getters.partner
    },
  },
  mounted() {
    // 振興醫院使用教學
    if(this.partnerid==1577 || this.artnerid==2085){
      this.srcDocument = "https://patient.curdoctor.com.tw/PDFviewer/web/viewer.html?file=https://upload.curdoctor.com.tw/document/20211115_chgh.pdf"
    }
    // 預設使用教學
    else{
      this.srcDocument = "https://patient.curdoctor.com.tw/PDFviewer/web/viewer.html?file=https://upload.curdoctor.com.tw/document/20221214.pdf"
    }
  },
}
</script>




